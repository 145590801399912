import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";

const FacebookIcon = dynamic(() => import("@mui/icons-material/Facebook"), {
    ssr: false,
});
const InstagramIcon = dynamic(() => import("@mui/icons-material/Instagram"), {
    ssr: false,
});
const LinkedInIcon = dynamic(() => import("@mui/icons-material/LinkedIn"), {
    ssr: false,
});
const TwitterIcon = dynamic(() => import("@mui/icons-material/Twitter"), {
    ssr: false,
});


const FooterSocials = ({direction = "column", include_header = true}) => {
    const footer_socials = useSelector(state => state.globalReducer.footer_socials);
    return (
        <>
            {include_header && (
                <Typography className="f-size_3 uppercase font-regular mb-3" component="h5">Our Socials</Typography>
            )}

            <div className="mt-2">
                <Stack direction={direction} spacing={1}>
                    {
                        footer_socials?.facebook_url && (
                            <a href={footer_socials.facebook_url} target="_blank"><IconButton  size="medium"  aria-label="Facebook" color="primary">
                                <FacebookIcon />
                            </IconButton></a>
                        )
                    }
                    {
                        footer_socials?.instagram_url && (
                            <a href={footer_socials.instagram_url} target="_blank"><IconButton aria-label="Instagram" color="primary">
                                <InstagramIcon />
                            </IconButton></a>
                        )
                    }
                    {
                        footer_socials?.linkedin_url && (
                            <a href={footer_socials.linkedin_url} target="_blank"><IconButton color="primary" aria-label="Linked In">
                                <LinkedInIcon />
                            </IconButton></a>
                        )
                    }
                    {
                        footer_socials?.twitter_url && (
                            <a href={footer_socials?.twitter_url} target="_blank"><IconButton color="primary" aria-label="Twitter">
                                <TwitterIcon />
                            </IconButton></a>
                        )
                    }
                </Stack>
            </div>
        </>
    )
}

export default FooterSocials